* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(104, 103, 103, 0.38);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #777;
}

.center-div {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.form-rounded {
    border-radius: 1rem !important;
}

.card-hover:hover {
    border: 1px solid #0d6efd !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300 !important;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #ddd;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

.sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #7386d5;
    color: #fff;
    transition: all 0.3s;
}

.sidebar.active {
    margin-left: -250px;
}

.sidebar .sidebar-header {
    padding: 20px;
    background: #6d7fcc;
}

sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
}

sidebar ul p {
    color: #fff;
    padding: 10px;
}

sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}

sidebar ul li a:hover {
    color: #7386d5;
    background: #fff;
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
    color: #fff;
    background: #6d7fcc;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386d5;
}

a.article,
a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

.content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    .sidebar {
        margin-left: -250px;
    }

    .sidebar.active {
        margin-left: 0;
    }

    .sidebarCollapse span {
        display: none;
    }
}

.show-hand {
    cursor: pointer;
}

.mh-60px {
    max-height: 60px;
}

.w-24px {
    width: 24px;
}

.w-16px {
    width: 16px;
}

.user-dropdown {
    right: 0 !important;
    left: unset !important;
}

.edit-user-profile {
    top: 12%;
    right: 4%;
}

/*NEW CSS*/

.h-100vh {
    height: 100vh;
}

.w-100vw {
    width: 100vw;
}

.w-50vw {
    max-width: 50vw;
}

.w-75vw {
    width: 75vw;
}

.w-fit-content {
    width: fit-content !important;
}

.bg-si-su {
    background-color: #dbe2e2 !important;
}

.center-ls-form {
    top: 50%;
    transform: translate(6%, -50%);
}

.bg-off-white {
    background-color: #f9f9f9;
}

.object-fit-cover {
    object-fit: cover;
}

.controller button {
    background-color: white;
    border: 2px solid #4CAF50;
    /* Green */
    color: black;
    /* border-radius: 8px; */
    margin: 5px 5px;
    cursor: pointer;
    width: 75px;
    height: 25px;
}

.controller button:hover {
    background-color: #4CAF50;
    /* Green */
    color: white;
}

.error-message {
    color: red;
    font-size: .7rem;
}

.spinner-dimension {
    width: 16px !important;
    height: 16px !important;
}